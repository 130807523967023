/* ================================== */
/* ============= GENERAL ============ */
/* ================================== */

body {
    background: #efefef;
}

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


/* ================================== */
/* ============== CARD ============== */
/* ================================== */

.card-body {
    flex: 1 1 auto;
    padding: 1rem;
}

.space-bottom {
    padding-bottom: 1.2rem;
}

.tab-step {
    font-size: 18px;
    font-weight: bold;
    color:  #0a6666;
    text-align: center;
}

.clickable {
    cursor: pointer;
}

/* ================================== */
/* ============== ICONS ============= */
/* ================================== */

.icon {
    color: #0a6666;
    text-shadow: 1px 1px 1px rgb(112, 112, 112);
    font-size: 1.3em;
}

.villa {
    color: #1a8941;
}

.bookstore {
    color: #c32c29;
}

.conservatory {
    color: #20a8d8;
}

.villa-paros {
    color: #ff0084;
}

.bg-secondary {
    background-color: #cfd5da !important;
}

.btn-paradox {
    background: #0a6666;
    color: #fff;
}

.bg-paradox {
    background: #cccccc;
    color: #0a6666;
}

.bk-title {
    color: #0a6666;
}

/* ================================== */
/* ======== BUTTON OF ROOMS ========= */
/* ================================== */

.btn-client {
    font-size: 13px;
    font-weight: bold;
    padding: 3%;
    overflow: hidden;
    color: rgb(255, 255, 255);
    background: #0a6666;
    text-align: center;
    padding-left: 3em;
    padding-right: 3em;
}

.btn-vimeo, .btn-vimeo:hover, .btn-vimeo:active, .btn-vimeo:visited {
    background-color: #0f8888 !important;
    border-color:  #0f8888 !important;
}

.btn-admin {
    font-size: 14px;
    overflow: hidden;
    color: rgb(255, 255, 255);
    text-align: left;
}

.room-title {
    font-weight: bold;
    text-align: left;
}

/* ================================== */
/* ============= T-SHIRT ============ */
/* ================================== */

.t-shirt {
    width: 100%;
    height: 100%;
    margin: auto;    
    display: block;
}

/* ================================== */
/* ========== NOTIFICATIONS ========= */
/* ================================== */

.notifications {
  max-height: 26rem;
  overflow-y: scroll;
}

.fa-circle {
  color: #20a8d8;
}

.fa-check-circle {
    color: #060707;
}

.fa-euro-sign {
    color: #060707;
}

.text-black {
    color: #060707;
}

.pp-border-menu {
    border: none !important;
}

.notification-status {
    display: inline-flex;
    align-items: center;
}

/* ================================== */
/* ======== CLIENT PAGE ======== */
/* ================================== */

.photos {
    opacity: .9;
}

.photos:hover {
    opacity: .5;
    transition: opacity .4s ease-out;
    -moz-transition: opacity .4s ease-out;
    -webkit-transition: opacity .4s ease-out;
    -o-transition: opacity .4s ease-out;
}

.photos .card-rgn::hover {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
  color: #252525;
  text-align: center;
  width: 100%;
  margin: 25% 0;
  position: absolute;
}

.card-rgn {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 45px;
  color: #f3f1f1;
  text-align: center;
  width: 100%;
  margin: 26% 0;
  position: absolute;
  opacity: 1;
  z-index: 1000;
  cursor: pointer;
}

.centered {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fa-map-pin {
  color: #0a6666;
}

.fa-exclamation-triangle { 
    color: #940909;
}

/* ================================== */
/* ============= RIBBON ============= */
/* ================================== */

.ribbon-online-cat {
    position: absolute;
    left: -7px;
    top: -7px;
    z-index: 1;
    overflow: hidden;
    width: 105px;
    height: 105px;
    text-align: right;
}

.ribbon-online-cat span {
    font-size: 12px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 120px;
    display: block;
    background-color: #0a6666;
    box-shadow: 0 3px 10px -5px black;
    position: absolute;
    top: 27px;
    left: -23px;
}

.ribbon-online-cat span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #000000;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #000000;
}

.ribbon-online-cat span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #000000;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #000000;
}

@media (min-width: 576px) {
    .bk-slider-sm {
        animation: right_to_left 10s infinite;
    }

    @keyframes right_to_left {
        from {
            margin-left: 100%;
        }
        to {
            margin-left: -600%;
        }
    }
}

@media (min-width: 768px) {
    .bk-slider-md {
        animation: right_to_left 7s infinite;
    }

    @keyframes right_to_left {
        from {
            margin-left: 100%;
        }
        to {
            margin-left: -300%;
        }
    }
}


/* ================================== */
/* ============= STEPS ============== */
/* ================================== */

.cl-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    margin: auto;
    overflow-x: hidden;
}

ul.stepper {
	counter-reset: section;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0.5em -3.2rem;
	padding: 0 1.5rem 1.5rem;
    cursor: pointer;
	>li {
		&:not(:last-of-type) {
			margin-bottom: .625rem;
		}
	}
	li {
		.astep {
			padding: 0 1.5rem;
			text-align: center;
			.circle {
				display: inline-block;
				color: #fff;
				border-radius: 50%;
				width: 1.75rem;
				height: 1.75rem;
				text-align: center;
				line-height: 1.7rem;
				margin-right: .5rem;
			}
			.label {
				display: inline-block;
				color: rgba(0, 0, 0, 0.38);
			}
		}
        .choice {
                font-weight: 300;
				color: rgba(51, 50, 50, 0.38);
			}
	}
	li.active {
		.astep {
			.circle {
				background-color: #0a6666 !important;
			}
			.label {
				font-weight: 600;
				color: rgba(0, 0, 0, 0.87);
			}
		}
	}
	li.completed {
		.astep {
			.circle {
				background-color: #0a6666 !important;
			}
			.label {
				font-weight: 600;
				color: rgba(0, 0, 0, 0.87);
			}
		}
	}
	li.todo {
		.astep {
			.circle {
				background-color: #a4b7c1 !important;
			}
			.label {
				font-weight: 600;
				color: rgba(0, 0, 0, 0.87);
			}
		}
	}
}

.stepper-horizontal {
	position: relative;
	display: flex;
	justify-content: space-between;
    cursor: pointer;
	>li {
		&:not(:last-of-type) {
			margin-bottom: 0 !important;
		}
	}
	li {
		display: flex;
		align-items: center;
		flex: 1;
		position: relative;
		.astep {
			.label {
				margin-top: .63rem;
			}
		}
		&:not(:first-child) {
			&:before {
				content: "";
				position: relative;
				flex: 1;
				margin: .5rem 0 0 0;
				height: 1px;
				background-color: rgba(0, 0, 0, 0.1);
			}
		}
		&:not(:last-child) {
			&:after {
				content: "";
				position: relative;
				flex: 1;
				margin: .5rem 0 0 0;
				height: 1px;
				background-color: rgba(0, 0, 0, 0.1);
			}
		}
	}
}

.primary-color {
	background-color: #0a6666 !important;
}

@media (max-width: 430px) {
	.stepper-horizontal {
		li {
            .astep {
				.label {
					margin-right: 0.5rem;
				}
            }
	    }
    }
    .stepper-horizontal::after {
		li {
            .astep {
                .label{
                    content: "\a";
                    white-space: pre;
                }
            }
	    }
    }
    .stepper-horizontal::before {
		li {
            .astep {
                .choice{
                    content: "\a";
                    white-space: pre;
                }
            }
	    }
    }
}

@media (max-width: 990px) {
	.cl-app-body {
		margin-top: 10px !important;
	}
}


/* ================================== */
/* =========== DATE PICKER ========== */
/* ================================== */

.example-custom-input {
    cursor: pointer;
    padding: 7px 18px;
    border: 0;
    border-radius: 3px;
    background-color: #20a8d8;
    font: inherit;
    color: #fff;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}


/* ================================== */
/* ===========  CUSTOM CSS ========== */
/* ================================== */

.img-rgn {
    padding-top: 13%;
    padding-bottom: 3%;
}

.rgn-width {
    width: 95%;
    padding-left: 7%;
}


/* ==================================== */
/* =========== REGION REBBONS ========== */
/* ==================================== */

#ribbon1 {
  background-color: #0a6666;
  width: 50%;
  padding: 3px;
  position: relative;
  margin-top: -1%;
  margin-left: auto;
  margin-right: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 22px;
  letter-spacing: 2px;
  color: #ffffff;
  text-align: center;
  box-shadow: 0 2px 6px #999999;
  border-radius: 10px;
}

// /*Ribbon Ends*/
// #ribbon1:before,
// #ribbon1:after {
//   content: "";
//   width: 3px;
//   bottom: 18px;
//   position: absolute;
//   display: block;
//   border: 25px solid #0a6666;
//   box-shadow: 0 8px 6px -6px #999999;
//   z-index: -2;
// }

// /*Left Ribbon End*/
// #ribbon1:before {
//   left: -42px;
//   border-right-width: 35px;
//   border-left-color: transparent;
// }

// /*Right Ribbon End*/
// #ribbon1:after {
//   right: -42px;
//   border-left-width: 35px;
//   border-right-color: transparent;
// }

/*Wrap*/
#span:before,
#span:after {
  content: "";
  bottom: 44px;
  position: absolute;
  display: block;
  border-style: solid;
  z-index: -2;
}

/*Left Ribbon Wrap*/
#span:before {
  left: 0;
  border-width: 0px 0px 20px 20px;
  border-color: transparent transparent #053b3b transparent;
}

/*Right Ribbon Wrap*/
#span:after {
  right: 0;
  border-width: 20px 0px 0px 20px;
  border-color: transparent transparent transparent #053b3b;
}

#ribbon2 {
        background-color: #0a6666;
        width: 50%;
        padding: 3px;
        position: relative;
        margin-top: 1%;
        margin-left: auto;
        margin-right: auto;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 22px;
        letter-spacing: 2px;
        color: #ffffff;
        text-align: center;
        box-shadow: 0 2px 6px #999999;
        border-radius: 10px;
    }

// /*Ribbon Ends*/
// #ribbon2:before,
// #ribbon2:after {
//   content: "";
//   width: 3px;
//   bottom: 18px;
//   position: absolute;
//   display: block;
//   border: 25px solid #0a6666;
//   box-shadow: 0 8px 6px -6px #999999;
//   z-index: -2;
// }

// /*Left Ribbon End*/
// #ribbon2:before {
//   left: -42px;
//   border-right-width: 35px;
//   border-left-color: transparent;
// }

// /*Right Ribbon End*/
// #ribbon2:after {
//   right: -42px;
//   border-left-width: 35px;
//   border-right-color: transparent;
// }

/*Wrap*/
#span:before,
#span:after {
  content: "";
  bottom: 44px;
  position: absolute;
  display: block;
  border-style: solid;
  z-index: -1;
}

/*Left Ribbon Wrap*/
#span:before {
  left: 0;
  border-width: 0px 0px 20px 20px;
  border-color: transparent transparent #053b3b transparent;
}

/*Right Ribbon Wrap*/
#span:after {
  right: 0;
  border-width: 20px 0px 0px 20px;
  border-color: transparent transparent transparent #053b3b;
}


.dropdown-menu-feat {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 9rem;
    padding: 0 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: #23282c;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c8ced3;
    border-radius: 0.25rem;
}

.dropdown-menu-feat.show {
    display: block;
}